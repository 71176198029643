<template>
  <div>
    <KTCodePreview v-bind:title="'Duyệt phiếu chuyển kho'">
      <template v-slot:preview>
        <div class="col-12 row" style="padding-left: 0px">
          <b-form-group class="col-3">
            <label>Mã phiếu:</label>
            <p>{{ codeStockBill }}</p>
          </b-form-group>
          <b-form-group class="col-3">
            <label>Ngày tạo:</label>
            <p>{{ dateCreate }}</p>
          </b-form-group>

          <b-form-group class="col-3 required-control">
            <label>Ngày chuyển kho:</label>
            <date-picker
              v-bind:disabled="status != 1"
              placeholder="Chọn ngày nhập kho"
              class="mb-2 mt-2 form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="$v.form.dateImport.$model"
              :state="validateState('dateImport')"
              aria-describedby="input-date-feedback"
            ></date-picker>
            <b-form-invalid-feedback id="input-date-feedback"
              >Vui lòng chọn ngày chuyển kho</b-form-invalid-feedback
            >
          </b-form-group>
        </div>

        <div class="col-12 row" style="padding-left: 0px">
          <b-form-group class="col-3 required-control">
            <label>Kho nguồn:</label>
            <b-form-select
              :disabled="true"
              class="mt-2"
              v-model="$v.form.sourceStoreSelected.$model"
              :options="listInventories"
              size="sm"
              :state="validateState('sourceStoreSelected')"
              value-field="id"
              text-field="name"
              @change="onChangeStore"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >Chọn kho nguồn</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group class="col-3 required-control">
            <label>Kho đích:</label>
            <b-form-select
              class="mt-2"
              v-model="$v.form.desStoreSelected.$model"
              :options="listInventories"
              size="sm"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >Chọn kho đích</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group class="col-3">
            <label>Tổng tiền:</label>
            <p>{{ formatMoney(totalPrice) }}</p>
          </b-form-group>
        </div>
        <div class="col-12 row" style="padding-left: 0px">
          <b-form-group class="col-6">
            <label>Nội dung:</label>
            <b-form-textarea
              size="sm"
              v-model="notes"
              :placeholder="'Thêm nội dung...'"
              :rows="3"
              :max-rows="3"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label-class="font-weight-bolder" class="col-3">
            <label>Người tạo:</label>
            <p>{{ createBy }}</p>
          </b-form-group>
        </div>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 27%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 7%">
                SL yêu cầu
              </th>
              <th scope="col" class="title-center" style="width: 13%">IMEI</th>
              <th scope="col" class="title-center" style="width: 7%">
                SL duyệt
                <i
                  class="fas fa-angle-double-down"
                  style="color: #000000; marging-left: 2px; padding-top: 2px"
                  title="Chuyển SL về 0"
                  @click="onClickToDown"
                ></i>
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 26%"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Thành tiền
              </th>
            </tr>
          </thead>

          <tbody v-for="item in listProductStock" :key="item.id">
            <ApproveStock
              v-bind:productItem="item"
              v-bind:status="status"
              v-on:save="save"
              v-on:update="updateItem"
              v-bind:editState="true"
              v-bind:canViewOriginalPrice="
                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
              "
            />
          </tbody>
          <tr>
            <td
              :colspan="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 3 : 3"
              class="tdTextAlignPrice"
            >
              Tổng
            </td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            ></td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              {{ formatMoney(totalPrice) }}
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit()"
          v-if="status === 1"
          >Duyệt</b-button
        >
        <router-link to="/require-stocks" tag="button" v-if="status === 1">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
        <router-link to="/require-stocks" tag="button" v-if="status !== 1">
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ApproveStock from '../../components/ApproveStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { unMaskPrice } from './../../../utils/common';
import localData from '../../../utils/saveDataToLocal';
import { TIME_TRIGGER } from '../../../utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      errors: [],
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      count: 0,
      notes: '',
      idStock: '',
      form: {
        sourceStoreSelected: null,
        desStoreSelected: null,
        dateImport: '',
      },
      isNew: true,
      createBy: '',
      status: 0,
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      totalQuantity: 0,
    };
  },
  validations: {
    form: {
      sourceStoreSelected: { required },
      desStoreSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    ApproveStock,
    datePicker,
  },
  created() {
    this.fetchStore();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu XNK', route: '/require-stocks' },
      { title: 'Duyệt phiếu chuyển kho' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let imeiCode = '';
      let quantity = 0;
      if (this.selectTypeSearch === 2) {
        imeiCode = option.item.imeiCode;
        quantity = 1;
      } else if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
      }
      this.searchProduct = option.item.productName;
      this.count++;
      let pro = {
        id: this.count,
        name: this.selectedClassProduct.productName,
        productCode: this.selectedClassProduct.productCode,
        barCode: this.selectedClassProduct.barCode,
        totalQuantityInStock:
          this.selectedClassProduct.totalQuantityInStock != null
            ? this.selectedClassProduct.totalQuantityInStock
            : 0,
        IMEI: imeiCode,
        proId: this.selectedClassProduct.id,
        price: this.selectedClassProduct.originalPrice,
        quantity: quantity,
        totalPrice: this.selectedClassProduct.originalPrice * quantity,
        productType: this.selectedClassProduct.productType,
      };
      this.listProductStock.push(pro);
      this.searchProduct = '';
      this.checkTotalMoney();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsProduct[0].data
        .filter((item) => {
          if (item.productName === null) {
            item.productName = '';
          }
          if (item.productCode === null) {
            item.productCode = '';
          }
          if (item.barCode === null) {
            item.barCode = '';
          }
          if (this.selectTypeSearch === 1) {
            return (
              item.productName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
              item.productCode.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
              item.barCode.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
          } else {
            return item.imeiCode.toLowerCase().indexOf(text.toLowerCase()) > -1;
          }
        })
        .slice(0, this.limit);

      this.filteredOptionsProduct = [
        {
          data: filteredData,
        },
      ];
      if (this.selectTypeSearch === 1) {
        this.fetchProduct();
      } else {
        this.fetchProductImei();
      }
    },
    fetchProduct() {
      this.listProduct = [];
      this.listProductSearch = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store?storeId=${this.$v.form.sourceStoreSelected.$model}&searchProduct=${this.searchProduct}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
      });
    },
    onSubmit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
        };
      });

      const data = {
        id: this.idStock,
        listProduct: listPro,
        destinationWarehouse: this.$v.form.desStoreSelected.$model,
        description: this.notes,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.put('stock-slips/approve-transfer', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.listProduct = [];
              this.makeToastSuccess(data.message);
              this.$router.push({
                name: 'list-require-stock',
              });
            } else {
              this.makeToastFaile(data.message);
              setTimeout(() => {
                this.isNew = true;
              }, TIME_TRIGGER);
            }
          })
          .catch(({ response }) => {
            this.makeToastFaile(response.$error);
            setTimeout(() => {
              this.isNew = true;
            }, TIME_TRIGGER);
          });
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
      this.checkTotalMoney();
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.listProductStock.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.price);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = item.price;
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    getStockById() {
      let id = this.$route.query.id;
      ApiService.setHeader();
      ApiService.get(`stocks/transfer-stock/${id}`).then(({ data }) => {
        let dataRes = data.data;
        this.codeStockBill = dataRes.code;
        this.dateCreate = moment(String(dataRes.createdAt)).format(
          'HH:mm DD/MM/YYYY'
        );
        this.$v.form.dateImport.$model = moment(
          String(dataRes.transferDate)
        ).format('DD-MM-YYYY');
        this.$v.form.sourceStoreSelected.$model = dataRes.sourceStoreId;
        this.$v.form.desStoreSelected.$model = dataRes.desStoreId;
        this.totalPrice = dataRes.totalAmount;
        this.notes = dataRes.description;
        this.createBy = dataRes.createdBy;
        this.status = dataRes.status;
        this.idStock = dataRes.id;

        dataRes.listDetail.forEach((element) => {
          this.count++;
          let dataItem = {
            id: element.id,
            name: element.productName,
            IMEI: element.productImei.replace(', ', '\n'),
            proId: element.productId,
            price: element.unitPrice,
            quantity: element.quantity,
            totalPrice: element.totalAmount,
            productType: element.productType,
            quantityRequire: element.quantity,
            barCode: element.barCode,
            productCode: element.productCode,
          };
          this.listProductStock.push(dataItem);
        });
        this.fetchProduct();
        this.checkTotalMoney();
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      if (this.selectTypeSearch === 2) {
        this.fetchProductImei();
      } else {
        this.fetchProduct();
      }
    },
    fetchProductImei() {
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.$v.form.sourceStoreSelected.$model}&searchProduct=${this.searchProduct}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
      });
    },
    onChangeSearchType() {
      if (this.selectTypeSearch === 2) {
        this.fetchProductImei();
      } else {
        this.fetchProduct();
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onClickToDown() {
      this.listProductStock.forEach((element) => {
        if (element.productType === 1) element.quantity = 0;
      });
    },
  },
};
</script>
